import { FIELD_TYPES } from "lib/consts";
import { getIsInputUnanswered } from "utils/input";
import Label from "./Label";
import styles from "./styles.module.css";

const Dropdown = ({
  field = {},
  indicateUnanswered,
  isReadonly,
  onChange,
  value,
  renderFormField,
  state,
}) => {
  const inputValue = value ?? "";

  const handleChange = (evt) => {
    onChange(field, evt.currentTarget.value);
  };

  const { label, noPlaceholder, placeholder, required } = field;

  const options = field.otherKey
    ? [
        ...field.options,
        {
          value: "other",
          label: "Other",
          details: {
            type: FIELD_TYPES.TEXT,
            key: field.otherKey,
            label: field.otherLabel || "Other:",
          },
        },
      ]
    : field.options;

  if(!noPlaceholder) {
    // if placeholder option does not exist, then add it
    const firstOptionValue = options[0]?.value;
    if(firstOptionValue || firstOptionValue === 0 || firstOptionValue === false) {
      options.unshift({ label: placeholder ?? "Choose..." });
    }
  }

  const selectedOption = options.find(option => {
    let optionValue = option.value ?? "";
    if(typeof optionValue === "number" && typeof inputValue === "string") {
      optionValue = `${optionValue}`;
    }
    return optionValue === inputValue;
  });
  const details = selectedOption?.details;

  const isUnanswered = getIsInputUnanswered(inputValue);

  return (
    <div className={styles.root}>
      <Label
        indicateUnanswered={indicateUnanswered}
        isReadonly={isReadonly}
        isRequired={required}
        isUnanswered={isUnanswered}
      >
        {label}
      </Label>
      {isReadonly
        ? <p className={styles.readonlyValue}>{!isUnanswered ? selectedOption?.label : ""}</p>
        : <select
            onChange={handleChange}
            className={`${styles.input} ${styles.dropdown}`}
            value={inputValue}
          >
            {options.map((option, index) => (
              <option key={index} value={option.value ?? ""}>
                {option.label}
              </option>
            ))}
          </select>
      }
      {details &&
        (Array.isArray(details) ? (
          details.map((field) => {
            return (
              <div className={styles.dynamicField} key={field.key}>
                {renderFormField({
                  field,
                  state,
                  onChange,
                })}
              </div>
            );
          })
        ) : (
          <div className={styles.dynamicField}>
            {renderFormField({
              field: details,
              state,
              onChange,
            })}
          </div>
        ))}
    </div>
  );
};

export default Dropdown;

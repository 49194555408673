import Dropdown from "./Dropdown";
import styles from "./styles.module.css";

const YearInput = ({
  field = {},
  indicateUnanswered,
  isReadonly,
  onChange,
  value,
}) => {
  const yearsToDisplay = [{ label: "Choose a year..." }];

  if(field.includeUnknown) {
    yearsToDisplay.push({ value: "unknown", label: "I don't remember" });
  }

  const rangeOfYears = field.range ?? 30;
  const currentYear = new Date().getFullYear();
  for(let year = currentYear; year >= currentYear - rangeOfYears; year--) {
    const yearString = `${year}`;
    yearsToDisplay.push({ value: yearString, label: yearString });
  }

  return (
    <Dropdown
      field={{...field, options: yearsToDisplay}}
      indicateUnanswered={indicateUnanswered}
      isReadonly={isReadonly}
      onChange={onChange}
      value={value}
    />
  );
};

export default YearInput;

import { useRef } from "react";
import { getIsInputUnanswered } from "utils/input";
import Button from "../Button";
import Label from "./Label";
import styles from "./styles.module.css";

// @todo make sure that there are no issues with number values (such as the radio input converting numbers to strings)

const Radio = ({
  field = {},
  indicateUnanswered,
  isReadonly,
  onChange,
  onFatal,
  value,
}) => {
  const ref = useRef({});

  const { label, options, name = field.key, required } = field;

  const handleChange = (evt) => {
    const fatal = evt.currentTarget.dataset["fatal"];
    if(fatal) {
      onFatal(fatal);
    } else {
      onChange(field, evt.currentTarget.value);
    }
  };

  const handleClick = (e) => {
    if (e.currentTarget.value === value) {
      onChange(field);
    }
  };

  const selectedOption = options?.find(o => o.value === value);

  const isUnanswered = getIsInputUnanswered(value);

  return (
    <div className={styles.root}>
      <Label
        indicateUnanswered={indicateUnanswered}
        isReadonly={isReadonly}
        isRequired={required}
        isUnanswered={isUnanswered}
      >
        {label}
      </Label>
      {isReadonly
        ? <p className={styles.readonlyValue}>{selectedOption?.label}</p>
        : <div className={styles.buttonGroup}>
            {options.map((option, index) => {
              const button = (
                <Button
                  onClick={() => {
                    ref.current[index].click();
                  }}
                  selected={option.value === value}
                >
                  {option.label}
                </Button>
              );
              return (
                <div key={option.value}>
                  <input
                    checked={option.value === value}
                    onChange={handleChange}
                    onClick={handleClick}
                    className={styles.hiddenInput}
                    name={name}
                    value={option.value}
                    type="radio"
                    ref={(element) => (ref.current[index] = element)}
                    data-fatal={option.fatal}
                  />
                  {button}
                </div>
              );
            })}
          </div>
      }
    </div>
  );
};

export default Radio;

import { ROLES } from "lib/consts";

export const getRoles = (user = {}) => {
  return user["https://foremost.vercel.app/roles"] || [];
};

export const getIsAdmin = (user = {}) => {
  const roles = getRoles(user);
  return roles.includes(ROLES.ADMIN);
};

export const getIsDoctor = (user = {}) => {
  const roles = getRoles(user);
  return roles.includes(ROLES.DOCTOR);
};

export const getIsVendor = (user = {}) => {
  const roles = getRoles(user);
  return roles.includes(ROLES.VENDOR);
};

export const getHasAllowedRole = (user = {}, roles = []) => {
  const userRoles = getRoles(user);
  const matchedRoles = userRoles.filter(r => roles.includes(r));
  return !!matchedRoles.length;
};

// function intentionally returns null unless user has a value; otherwise, values for "isAdmin", etc are wrongly false
export const getRole = (user) => {
  if(!user) {
    return null;
  }
  return {
    isAdmin: getIsAdmin(user),
    isDoctor: getIsDoctor(user),
    isVendor: getIsVendor(user),
  };
};

// takes a user record from the DB and returns a string for the user type
export const getUserTypeFromDbUser = (dbUser) => {
  switch(dbUser?._type) {
    case "DoctorUser":
      return "Doctor";
    case "VendorUser":
      return "Vendor";
    default:
      return "";
  }
};

import { getFullName } from "utils/data";
import { getShortLabelFromDoctorSpecialty } from "./doctor";

export const getChaperoneOptions = (chaperoneRecords) => {
  return chaperoneRecords?.map(chaperone => ({
    value: chaperone._id,
    label: `${chaperone.nameFirst} ${chaperone.nameLast}`
  }));
};

export const getDoctorOptions = (doctorRecords) => {
  return doctorRecords?.map(doctor => ({
    value: doctor._id,
    label: `${getFullName(doctor)} (${getShortLabelFromDoctorSpecialty(doctor.specialty)})`
  }));
};

export const getInsurerOptions = (insurerRecords) => {
  return insurerRecords?.map(insurer => ({
    value: insurer._id,
    label: insurer.name
  }));
};

export const getLocationOptions = (locationRecords) => {
  return locationRecords?.map(location => ({
    value: location._id,
    label: location.name
  }));
};

export const getVendorOptions = (vendorRecords) => {
  return vendorRecords?.map(vendor => ({
    value: vendor._id,
    label: vendor.name
  }));
};

export const optionsMonth = [
  {
    label: "January",
    value: "january"
  },
  {
    label: "February",
    value: "february"
  },
  {
    label: "March",
    value: "march"
  },
  {
    label: "April",
    value: "april"
  },
  {
    label: "May",
    value: "may"
  },
  {
    label: "June",
    value: "june"
  },
  {
    label: "July",
    value: "july"
  },
  {
    label: "August",
    value: "august"
  },
  {
    label: "September",
    value: "september"
  },
  {
    label: "October",
    value: "october"
  },
  {
    label: "November",
    value: "november"
  },
  {
    label: "December",
    value: "december"
  },
];

import { createContext, useContext, useState } from "react";

const SessionContext = createContext();
export const useSession = () => useContext(SessionContext);

const SessionContextProvider = ({ children }) => {
  const [schedulerDate, setSchedulerDate] = useState();

  return (
    <SessionContext.Provider
      value={{ schedulerDate, setSchedulerDate }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export default SessionContextProvider;

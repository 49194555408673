import { createContext, useContext, useState } from "react";
import FormModal from "components/Modal/Form";

const FormModalContext = createContext();
export const useFormModal = () => useContext(FormModalContext);

const FormModalContextProvider = ({ children }) => {
  const [config, setConfig] = useState({});

  const { description, formFields, heading, onSubmit } = config;
  const showModal = !!formFields && !!onSubmit;

  const handleClose = () => setConfig({});

  const handleSubmit = async (formData) => {
    await onSubmit(formData);
    handleClose();
  };

  return (
    <FormModalContext.Provider
      value={{ setFormModal: setConfig }}
    >
      {children}
      <FormModal
        description={description}
        formFields={formFields}
        heading={heading}
        onClose={handleClose}
        onSubmit={handleSubmit}
        showModal={showModal}
      />
    </FormModalContext.Provider>
  );
};

export default FormModalContextProvider;

import { createContext, useContext, useState } from "react";
import Loader from "components/Loader";

const LoaderContext = createContext();
export const useLoader = () => useContext(LoaderContext);

const LoaderContextProvider = ({ children }) => {
  const [isLoaderShown, setIsLoaderShown] = useState(false);

  return (
    <LoaderContext.Provider
      value={{
        showLoader: () => setIsLoaderShown(true),
        hideLoader: () => setIsLoaderShown(false)
      }}
    >
      {children}
      <Loader showLoader={isLoaderShown} />
    </LoaderContext.Provider>
  );
};

export default LoaderContextProvider;

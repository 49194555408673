import { useUser } from "@auth0/nextjs-auth0/client";
import { getRole } from "lib/user";

// gets Auth0 user and returns info on roles
// value returned from "useRole" will be null until request completes - use accordingly
// we probably don't want to handle case of not finding a user because that's when Auth0 forces login (which we don't want to interfere with)

const useRole = () => {
  const { user } = useUser();

  return getRole(user);
};

export default useRole;

const { DateTime } = require("luxon");
import { TIMEZONE } from "lib/consts";

// get required string format for date input from a date object, ISO time string, or falsy
// input date must be based on UTC time
// output is based on UTC time
export const getDateInputFormat = (date) => {
  if(!date) {
    return "";
  }
  let dateObj = date;
  if(typeof date !== "object") {
    dateObj = new Date(date);
  }
  const dateIsoString = dateObj.toISOString();
  return dateIsoString.split("T")[0];
};

// get required string format for datetime input from a date object, ISO time string, or falsy
// takes date/time from any timezone
// output is based on user's local timezone
export const getDateTimeInputFormat = (date) => {
  if(!date) {
    return "";
  }
  let dateObj = date;
  if(typeof date !== "object") {
    dateObj = new Date(date);
  }
  const year = dateObj.toLocaleString("en-US", { year: "numeric" });
  const month = dateObj.toLocaleString("en-US", { month: "2-digit" });
  const day = dateObj.toLocaleString("en-US", { day: "2-digit" });
  const hour = dateObj.toLocaleString("en-US", { hour: "2-digit", hourCycle: "h23" });
  let minute = dateObj.toLocaleString("en-US", { minute: "2-digit" });
  // accounting for browser issues with "2-digit" option for "minute"
  if(minute.toString().length === 1) {
    minute = "0" + minute;
  }
  return `${year}-${month}-${day}T${hour}:${minute}`;
};

// takes UTC-based (midnight GMT) date (as date object, ISO string, or falsy) and returns date (without time) as a string in either abbreviated or long form
export const getUTCDateAsString = (date, useLongForm = false) => {
  if(!date) {
    return "";
  }
  const dateObj = new Date(date);
  const options = {
    year: "numeric",
    month: useLongForm ? "long" : "numeric",
    day: "numeric",
    timeZone: "utc"
  };
  return dateObj.toLocaleDateString("en-US", options);
};

// get string (in either abbreviated or long form / with or without weekday) for date (without time) from a date object, ISO time string, or falsy
export const getDateAsString = (date, {
  includeWeekday = false,
  timeZone = TIMEZONE,
  useLongForm = false
} = {}) => {
  if(!date) {
    return "";
  }
  const dateObj = new Date(date);
  const options = {
    year: "numeric",
    month: useLongForm ? "long" : "numeric",
    day: "numeric"
  };
  if(includeWeekday) {
    options.weekday = "long";
  }
  if(timeZone) {
    options.timeZone = timeZone;
  }
  return dateObj.toLocaleDateString("en-US", options);
};

// get string for date/time from a date object, ISO time string, or falsy
export const getDateTimeAsString = (date, { timeZone = TIMEZONE } = {}) => {
  if(!date) {
    return "";
  }
  const dateObj = new Date(date);
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit"
  };
  if(timeZone) {
    options.timeZone = timeZone;
  }
  return dateObj.toLocaleString("en-US", options);
};

// get string for time from a date object, ISO time string, or falsy
export const getTimeAsString = (date, { timeZone = TIMEZONE } = {}) => {
  if(!date) {
    return "";
  }
  const dateObj = new Date(date);
  const options = {
    hour: "numeric",
    minute: "2-digit"
  };
  if(timeZone) {
    options.timeZone = timeZone;
  }
  return dateObj.toLocaleTimeString("en-US", options);
};

// "isoDate" format can be:
//   - abstract (no offset): "2020-06-26" or "2020-06-26T14:25:08.000" (less specific times work as well)
//      - date will be established according to "zone" timezone in "fromISO" method
//   - specific (with offset): ending with timezone offset (like "Z" or "+03:00")
//      - date will be established according to offset, and "zone" timezone (in "fromISO" method) will be subsequently applied
//      - "zone" sets the timezone but does NOT shift the date value
// returns midnight of specified date and midnight of next day (for the specified "zone" timezone)
export const getBoundsForISODate = (isoDate) => {
  const date = DateTime.fromISO(isoDate, { zone: TIMEZONE });
  const midnightDate = date.startOf("day");
  const midnightNextDay = midnightDate.plus({ days: 1 });
  return {
    start: midnightDate.toJSDate(),
    end: midnightNextDay.toJSDate()
  };
};

export const getBoundsForToday = () => {
  const todayInTimezone = DateTime.local({ zone: TIMEZONE });
  const midnightToday = todayInTimezone.startOf("day");
  const midnightTomorrow = midnightToday.plus({ days: 1 });
  return {
    midnightToday: midnightToday.toJSDate(),
    midnightTomorrow: midnightTomorrow.toJSDate()
  };
};

export const getLuxonNow = () => {
  return DateTime.local({ zone: TIMEZONE });
};

// get an ISO date string (e.g. "2020-12-01") for now (today)
export const getNowISODate = () => {
  const luxonNow = getLuxonNow();
  return luxonNow.toISODate();
};

// get a Luxon DateTime object from an ISO string or JS date
// will be established in specified timezone
// falsy (=> null, undefined, "") will generate an invalid DateTime object (similar to an invalid JS date)
// if ISO string contains an offset (like "Z" or "+03:00"), the Luxon date will be set according to the offset timezone
//   (more precisely, it will be set based on UTC, and then shifted according to the offset)
// if ISO string contains no offset, the Luxon date will be set according to the config timezone (in "options")
export const getLuxonDate = (date, { timeZone = TIMEZONE } = {}) => {
  const options = { zone: timeZone };
  if(typeof date === "string") {
    return DateTime.fromISO(date, options);
  } else {
    return DateTime.fromJSDate(date, options);
  }
};

// get an ISO date string (e.g. "2020-12-01") from a Luxon DateTime, JS date, or ISO string
// falsy (=> null, undefined, "") will return null
export const getISODate = (date) => {
  const isLuxonDate = DateTime.isDateTime(date);
  const luxonDate = isLuxonDate ? date : getLuxonDate(date);
  return luxonDate.toISODate();
};

import { useRef } from "react";
import { FIELD_TYPES } from "lib/consts";
import { getIsMultiselectUnanswered } from "utils/input";
import Button from "../Button";
import Label from "./Label";
import styles from "./styles.module.css";

const MultiselectButtons = ({
  field = {},
  indicateUnanswered,
  isReadonly,
  onChange,
  values,
  renderFormField,
  state,
}) => {
  const ref = useRef({});

  const { label, required } = field;

  const handleChange = (evt) => {
    onChange(field,{
      ...values,
      [evt.currentTarget.name]: evt.currentTarget.checked,
    });
  };

  const options = field.otherKey
    ? [
        ...field.options,
        {
          value: "other",
          label: "Other",
        },
      ]
    : field.options;

  const otherSelected = values["other"];

  const selectedOptionsAsString = options.filter(o => values[o.value]).map(o => o.label).join(", ");

  const isUnanswered = getIsMultiselectUnanswered(values);

  return (
    <div className={styles.root}>
      <Label
        indicateUnanswered={indicateUnanswered}
        isReadonly={isReadonly}
        isRequired={required}
        isUnanswered={isUnanswered}
      >
        {label}
      </Label>
      {isReadonly
        ? <p className={styles.readonlyValue}>{selectedOptionsAsString}</p>
        : <div className={styles.buttonGroup}>
            {options.map((option, index) => {
              const button = (
                <Button
                  onClick={() => {
                    ref.current[index].click();
                  }}
                  selected={values[option.value] ?? false}
                >
                  {option.label}
                </Button>
              );
              return (
                <div key={index}>
                  <input
                    checked={values[option.value] ?? false}
                    onChange={handleChange}
                    className={styles.hiddenInput}
                    name={option.value}
                    type="checkbox"
                    ref={(element) => (ref.current[index] = element)}
                  />
                  {button}
                </div>
              );
            })}
          </div>
      }
      {options.map((option) => {
        if (values[option.value] !== true) return;
        if (!option.details) return;
        return Array.isArray(option.details) ? (
          option.details.map((field) => {
            return (
              <div className={styles.option} key={field.key}>
                {renderFormField({ field, state, onChange })}
              </div>
            );
          })
        ) : (
          <div className={styles.option} key={option.details.key}> 
            {renderFormField({ field: option.details, state, onChange })}
          </div>
        );
      })}
      <div className={styles.option}>
        {otherSelected &&
          renderFormField({
            field: {
              type: FIELD_TYPES.TEXT,
              key: field.otherKey,
              label: field.otherLabel || "Other:",
            },
            state,
            onChange,
          })}
      </div>
    </div>
  );
};

export default MultiselectButtons;

import { useEffect, useState } from "react";
import Button from "components/Button";
import { useAlert } from "contexts/alert";
import { FILE_TYPES, FILE_TYPES_CONFIG } from "lib/consts";
import { uploadToBucket } from "lib/upload";
import Label from "./Label";
import styles from "./styles.module.css";

// @todo Could have a race condition (though not likely) if upload was removed while a new upload was being added (in newFileUploads effect)

const File = ({
  field = {},
  onChange,
  onUploadEnd = () => {},
  onUploadStart = () => {},
  value
}) => {
  const [failedFileUploads, setFailedFileUploads] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [newFileUploads, setNewFileUploads] = useState([]);

  const {
    allowedFileTypes = [],
    label,
    multiple,
  } = field;

  const successfulFileUploads = value ? (multiple ? value : [value]) : [];

  const otherInputProps = {};
  let subLabel;

  const allowedMimeTypes = allowedFileTypes.map(typeKey => FILE_TYPES_CONFIG[typeKey].mimeType);
  if(allowedFileTypes.length) {
    otherInputProps.accept = allowedMimeTypes.join(",");
    if(allowedFileTypes.length === 1 && allowedFileTypes.includes(FILE_TYPES.PDF)) {
      subLabel = "File format must be PDF (Note that MS Word can save a document as a PDF)";
    }
  }

  const { setAlert } = useAlert();

  // @todo must be handled differently for drag-and-drop
  const handleClick = (e) => {
    // clearing input value for situation where same set of files is attempted (won't trigger "onChange" if value is the same)
    e.currentTarget.value = null;
    if(failedFileUploads.length) {
      setFailedFileUploads([]);
    }
  };

  const handleChange = async (e) => {
    const files = Array.from(e.currentTarget.files);

    if(allowedFileTypes.length) {
      const hasForbiddenFileType = files.some(file => !allowedMimeTypes.includes(file.type));
      if(hasForbiddenFileType) {
        setAlert("Upload aborted because one or more files is an invalid file type. Please try again.");
        return;
      }
    }

    onUploadStart();
    setIsUploading(true);
    const filesUploaded = [];
    const filesNotUploaded = [];
    const uploadFile = async (file, fileIndex) => {
      const storageFileName = `${Date.now()}-${fileIndex}-${file.name}`;
      const { success } = await uploadToBucket(file, storageFileName);
      if(success) {
        filesUploaded.push({
          name: file.name,
          storageFileName
        });
      } else {
        filesNotUploaded.push(file.name);
      }
    };
    await Promise.all(files.map((file, index) => uploadFile(file, index)));
    if(filesNotUploaded.length) {
      setFailedFileUploads(filesNotUploaded);
    }
    if(filesUploaded.length) {
      setNewFileUploads(filesUploaded);
    }
    onUploadEnd();
    setIsUploading(false);
  };

  const handleRemoveFileUpload = (storageFileName) => {
    let newFieldValue;
    if(multiple) {
      const newSuccessfulFileUploads = successfulFileUploads.filter(file => file.storageFileName !== storageFileName);
      newFieldValue = newSuccessfulFileUploads.length ? newSuccessfulFileUploads : undefined;
    } else {
      newFieldValue = undefined;
    }
    onChange(field, newFieldValue);
  };

  // updating input value in useEffect, rather than in handleChange, to avoid slow state update for higher level form data
  useEffect(() => {
    if(newFileUploads.length) {
      const newFieldValue = multiple ? [...successfulFileUploads, ...newFileUploads] : newFileUploads[0];
      onChange(
        field,
        newFieldValue
      );
    }
  }, [newFileUploads]);

  return (
    <div className={styles.root}>
      <Label subLabel={subLabel}>{label}</Label>
      <input
        className={styles.fileInput}
        multiple={!!multiple}
        onChange={handleChange}
        onClick={handleClick}
        type="file"
        {...otherInputProps}
      />
      {isUploading && <div className={styles.uploadIndicator}>UPLOADING...</div>}
      {!!successfulFileUploads.length && (
        <div className={styles.uploadsWrapper}>
          {successfulFileUploads.map((file, index) => (
            <div className={styles.upload} key={index}>
              <div>Uploaded: {file.name}</div>
              <Button onClick={() => handleRemoveFileUpload(file.storageFileName)} style="inline" danger>Remove</Button>
            </div>
          ))}
        </div>
      )}
      {!!failedFileUploads.length && (
        <div className={styles.uploadsWrapper}>
          {failedFileUploads.map((name, index) => (
            <div className={styles.errorText} key={index}>
              Failed to upload: {name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default File;

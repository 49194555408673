import { createContext, useContext, useState } from "react";
import ConfirmationModal from "components/Modal/Confirmation";

const ConfirmContext = createContext();
export const useConfirm = () => useContext(ConfirmContext);

const ConfirmContextProvider = ({ children }) => {
  const [config, setConfig] = useState({});

  const { confirmButtonText, onConfirm, prompt } = config;
  const showModal = !!onConfirm && !!prompt;

  const handleClose = () => setConfig({});

  const handleConfirm = async () => {
    await onConfirm();
    handleClose();
  };

  return (
    <ConfirmContext.Provider
      value={{ setConfirm: setConfig }}
    >
      {children}
      <ConfirmationModal
        confirmButtonText={confirmButtonText}
        onClose={handleClose}
        onConfirm={handleConfirm}
        prompt={prompt}
        showModal={showModal}
      />
    </ConfirmContext.Provider>
  );
};

export default ConfirmContextProvider;

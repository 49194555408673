export const getFullName = (obj) => {
  if(obj?.nameFirst && obj?.nameLast) {
    return `${obj.nameFirst} ${obj.nameLast}`;
  } else if(obj?.nameFirst) {
    return obj.nameFirst;
  } else if(obj?.nameLast) {
    return obj.nameLast;
  } else if(obj?.name) {
    return obj.name;
  }

  return "";
};
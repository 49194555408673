import styles from "./index.module.css";

const Loader = ({ showLoader = false }) => {
  if(!showLoader) {
    return null;
  }

  return (
    <div className={styles.background} onClick={e => e.stopPropagation()}>
      <div className={styles.content}>
        Loading...
      </div>
    </div>
  );
};

export default Loader;

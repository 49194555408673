import { FIELD_TYPES } from "lib/consts";
import { getIsInputUnanswered } from "utils/input";
import Label from "./Label";
import Radio from "./Radio";
import styles from "./styles.module.css";

const DynamicRadio = ({
  field = {},
  indicateUnanswered,
  isReadonly,
  onChange,
  value,
  renderFormField,
  state,
}) => {
  const { label, required } = field;

  const options = field.otherKey
    ? [
        ...field.options,
        {
          value: "other",
          label: "Other",
          details: {
            type: FIELD_TYPES.TEXT,
            key: field.otherKey,
            label: field.otherLabel || "Other:",
          },
        },
      ]
    : field.options;
  const selectedOption = options.find((o) => o.value === value);
  const details = selectedOption?.details;
  const radioField = {
    key: field.key,
    options,
  };

  const isUnanswered = getIsInputUnanswered(value);

  return (
    <div className={styles.root}>
      <div>
        <Label
          indicateUnanswered={indicateUnanswered}
          isReadonly={isReadonly}
          isRequired={required}
          isUnanswered={isUnanswered}
        >
          {label}
        </Label>
        <Radio
          field={radioField}
          isReadonly={isReadonly}
          onChange={onChange}
          value={value}
        />
      </div>
      {details &&
        <div className={styles.dynamicFields}>
          {Array.isArray(details) ? (
            details.map((field) => {
              return (
                <div className={styles.dynamicField} key={field.key}>
                  {renderFormField({
                    field,
                    state,
                    onChange,
                  })}
                </div>
              );
            })
          ) : (
            <div className={styles.dynamicField}>
              {renderFormField({
                field: details,
                state,
                onChange,
              })}
            </div>
          )}
        </div>
      }
    </div>
  );
};

export default DynamicRadio;

import { useUser as useAuth0User } from "@auth0/nextjs-auth0/client";
import { createContext, useContext } from "react";
import useProfile from "hooks/profile";
import useRole from "hooks/role";

// provides Auth0 user, Auth0 role values, and Mongo DB user record
// "role" and "user" will be nullish until each request (done by the hooks) completes - use accordingly

const UserContext = createContext();
export const useUser = () => useContext(UserContext);

const UserContextProvider = ({ children }) => {
  const { user: auth0User } = useAuth0User();
  const role = useRole();
  const user = useProfile();

  return (
    <UserContext.Provider
      value={{ auth0User, role, user }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;

import { useState } from "react";
import TextInput from "components/Input/Text";
import ActionsModal from "../Actions";
import styles from "./index.module.css";

const matchKeyword = "CONFIRM";

const DangerConfirmationModal = ({
  onClose,
  onConfirm,
  prompt,
  showModal,
}) => {
  const [text, setText] = useState("");

  const handleClose = () => {
    onClose();
    setText("");
  };

  const handleConfirm = async () => {
    await onConfirm();
    setText("");
  };

  return (
    <ActionsModal
      onClose={handleClose}
      onSubmit={handleConfirm}
      showModal={showModal}
      stackLevel="120"
      submitButtonText="Confirm"
      submitDisabled={text !== matchKeyword}
    >
      <h1 className="heading2">{prompt}</h1>
      <p className={styles.paragraph}>Type {`"${matchKeyword}"`} to proceed</p>
      <TextInput
        onChange={(_, value) => setText(value)}
        value={text}
      />
    </ActionsModal>
  );
};

export default DangerConfirmationModal;

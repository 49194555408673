import axios from "axios";

export const post = async (path, data, config) => {
  return await axios.post(path, data, config);
};

export const put = async (path, data, config) => {
  return await axios.put(path, data, config);
};

export const destroy = async (path, config) => {
  return await axios.delete(path, config);
};

export const get = async (path, config) => {
  return await axios.get(path, config);
};

import ActionsModal from "../Actions";
import styles from "./index.module.css";

const AlertModal = ({
  onAcknowledge,
  prompt
}) => {
  return (
    <ActionsModal
      onClose={() => {}}
      onSubmit={onAcknowledge}
      showModal={!!prompt}
      isSingleAction
      stackLevel="130"
      submitButtonText="OK"
    >
      <div className={styles.text}>{prompt}</div>
    </ActionsModal>
  );
};

export default AlertModal;

import clsx from "clsx";
import Label from "./Label";
import styles from "./styles.module.css";

const InputGroup = ({
  field,
  fields,
  isReadonly,
  onChange,
  values,
  renderFormField,
}) => {
  let fieldsInGroup = fields ?? field?.fields;
  if (!Array.isArray(fieldsInGroup)) {
    fieldsInGroup = [fieldsInGroup];
  }

  const handleChange = (childField, value) => {
    onChange(field, { ...values, [childField.key]: value });
  };

  const fieldStyle = {};
  if(isReadonly) {
    fieldStyle.width = `${100 / (field?.displayColumns ?? 2)}%`;
  }

  return (
    <div className={styles.group}>
      <Label
        isGroupLabel
        isReadonly={isReadonly}
      >
        {field?.label}
      </Label>
      <div className={clsx({ [styles.readonlyColumns]: isReadonly })}>
        {fieldsInGroup.map((fieldInGroup) => {
          return (
            <div className={styles.field} key={fieldInGroup.key} style={fieldStyle}>
              {renderFormField({
                field: fieldInGroup,
                isReadonly: fieldInGroup.isReadonly,
                state: values,
                onChange: handleChange,
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default InputGroup;

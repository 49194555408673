import Link from "next/link";
import styles from "./index.module.css";

const styleDict = {
  default: styles.button,
  orange_outline: styles.orange_outline,
  orange_fill: styles.orange_fill,
  blue_fill: styles.blue_fill,
  blue_toolbar: styles.blue_toolbar,
  white_toolbar: styles.white_toolbar,
  blue_panel: styles.blue_panel,
  inline: styles.inline,
  inline_no_underline: styles.inline_no_underline,
};

const Button = ({
  children,
  danger,
  disabled = false,
  download,
  href,
  onClick,
  openInNewWindow,
  selected = false,
  style = "default",
  type = "button",
}) => {
  const buttonStyle = styleDict[style];
  let buttonClass = selected
    ? `${styles.button} ${buttonStyle} ${styles.selected}`
    : `${styles.button} ${buttonStyle}`;

  if(danger) {
    buttonClass += ` ${styles.danger}`;
  }

  const linkProps = {};
  if(openInNewWindow) {
    linkProps.target = "_blank";
    linkProps.rel = "noreferrer";
  }
  if(download) {
    linkProps.download = download;
  }

  if(href) {
    return (
      <Link
        className={`${styles.link} ${buttonClass} ${styles.centerText}`}
        href={href}
        {...linkProps}
      >
        {children}
      </Link>
    );
  }

  return (
    <button className={buttonClass} type={type} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
};

export default Button;

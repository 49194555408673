import { createContext, useContext, useState } from "react";
import DangerConfirmationModal from "components/Modal/DangerConfirm";

const DangerConfirmContext = createContext();
export const useDangerConfirm = () => useContext(DangerConfirmContext);

const DangerConfirmContextProvider = ({ children }) => {
  const [config, setConfig] = useState({});

  const { onConfirm, prompt } = config;
  const showModal = !!onConfirm && !!prompt;

  const handleClose = () => setConfig({});

  const handleConfirm = async () => {
    await onConfirm();
    handleClose();
  };

  return (
    <DangerConfirmContext.Provider
      value={{ setDangerConfirm: setConfig }}
    >
      {children}
      <DangerConfirmationModal
        onClose={handleClose}
        onConfirm={handleConfirm}
        prompt={prompt}
        showModal={showModal}
      />
    </DangerConfirmContext.Provider>
  );
};

export default DangerConfirmContextProvider;

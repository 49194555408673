import { optionsMonth } from "lib/inputOptions";
import Dropdown from "./Dropdown";
import styles from "./styles.module.css";

optionsMonth.unshift({ label: "Choose a month..." });

const MonthInput = ({
  field = {},
  indicateUnanswered,
  isReadonly,
  onChange,
  value,
}) => {
  return (
    <Dropdown
      field={{ ...field, options: optionsMonth }}
      indicateUnanswered={indicateUnanswered}
      isReadonly={isReadonly}
      onChange={onChange}
      value={value}
    />
  );
};

export default MonthInput;

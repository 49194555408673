import { useRef } from "react";
import MultiselectButtons from "./MultiselectButtons";
import styles from "./styles.module.css";

const BodyParts = ({
  field,
  indicateUnanswered,
  isReadonly,
  onChange,
  values,
  renderFormField,
  state,
}) => {
  return (
    <MultiselectButtons
      field={field}
      indicateUnanswered={indicateUnanswered}
      isReadonly={isReadonly}
      onChange={onChange}
      options={field?.options}
      values={values}
      renderFormField={renderFormField}
      state={state}
    />
  );
};

export default BodyParts;

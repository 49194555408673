import { get } from "lib/api";

export const uploadToBucket = async (file, fileName) => {
  const { data: { url, fields } } = await get("/api/bucket/upload", {
    params: {
      fileName: encodeURIComponent(fileName)
    }
  });
  const formData = new FormData();
  Object.entries({ ...fields, file }).forEach(([key, value]) => {
    formData.append(key, value);
  });
  try {
    const upload = await fetch(url, {
      method: "POST",
      body: formData,
      mode: "cors",
    });
    if(upload.ok) {
      return { success: true };
    } else {
      throw new Error("upload failed");
    }
  } catch {
    return { success: false };
  }
};

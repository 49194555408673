import useSWR from "swr";
import fetcher from "utils/fetcher";

// be aware the user "data" will be undefined until request completes - use accordingly

const useProfile = () => {
  const { data, error, isLoading } = useSWR({ url: "/api/user" }, fetcher);

  // @todo throw error if no user record found?
  // if(error || (!isLoading && !data)) {
  //   throw new Error("User record not found");
  // }

  return data;
};

export default useProfile;

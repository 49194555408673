import { createContext, useContext, useState } from "react";
import AlertModal from "components/Modal/Alert";

const AlertContext = createContext();
export const useAlert = () => useContext(AlertContext);

const AlertContextProvider = ({ children }) => {
  const [alertPrompt, setAlertPrompt] = useState();

  return (
    <AlertContext.Provider
      value={{ setAlert: setAlertPrompt }}
    >
      {children}
      <AlertModal
        onAcknowledge={() => setAlertPrompt()}
        prompt={alertPrompt}
      />
    </AlertContext.Provider>
  );
};

export default AlertContextProvider;

import { getIsInputUnanswered } from "utils/input";
import Label from "./Label";
import styles from "./styles.module.css";

const Textarea = ({
  expanded,
  field = {},
  indicateUnanswered,
  isReadonly,
  onChange,
  value,
}) => {
  const inputValue = value ?? "";

  const handleChange = (evt) => {
    onChange(field,evt.currentTarget.value);
  };

  const { label, required } = field;

  const isUnanswered = getIsInputUnanswered(inputValue);

  return (
    <div className={`${styles.root} ${expanded ? styles.expanded : ""}`}>
      <Label
        indicateUnanswered={indicateUnanswered}
        isReadonly={isReadonly}
        isRequired={required}
        isUnanswered={isUnanswered}
      >
        {label}
      </Label>
      {isReadonly
        ? <p className={styles.readonlyValue}>{inputValue}</p>
        : <textarea
            className={`${styles.input} ${styles.textarea} ${expanded ? styles.expanded : ""}`}
            onChange={handleChange}
            value={inputValue}
          />
      }
    </div>
  );
};

export default Textarea;

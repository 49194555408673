import DynamicRadio from "./DynamicRadio";
import { FIELD_TYPES } from "lib/consts";

const DynamicYesNo = ({
  field,
  indicateUnanswered,
  isReadonly,
  onChange,
  value,
  renderFormField,
  state
}) => {
  let { details } = field;
  if(!details) {
    details = {
      type: FIELD_TYPES.TEXT,
      key: `${field.key}_details`,
      label: "If yes, please elaborate:",
    };
  }

  const options = [
    {
      value: "no",
      label: "No",
    },
    {
      value: "yes",
      label: "Yes",
      details,
    },
  ];
  return (
    <DynamicRadio
      field={{...field, details, options}}
      indicateUnanswered={indicateUnanswered}
      isReadonly={isReadonly}
      onChange={onChange}
      value={value}
      renderFormField={renderFormField}
      state={state}
    />
  );
};

export default DynamicYesNo;

import Button from "components/Button";
import Modal from "..";
import styles from "./index.module.css";

const ActionsModal = ({
  children,
  onClose,
  onSubmit,
  showModal,
  isSingleAction = false,
  stackLevel,
  submitButtonText = "Submit",
  submitDisabled = false,
}) => {
  return (
    <Modal
      onClose={onClose}
      showModal={showModal}
      stackLevel={stackLevel}
    >
      <div className={styles.container}>
        {children}
        <div className={styles.actions}>
          {!isSingleAction && <Button style="orange_outline" onClick={onClose}>Cancel</Button>}
          <Button style="blue_fill" onClick={onSubmit} disabled={submitDisabled}>{submitButtonText}</Button>
        </div>
      </div>
    </Modal>
  );
};

export default ActionsModal;

import { getIsInputUnanswered } from "utils/input";
import Label from "./Label";
import MonthInput from "./Month";
import YearInput from "./Year";
import styles from "./styles.module.css";

const MonthYearInput = ({
  field = {},
  indicateUnanswered,
  isReadonly,
  onChange,
  value,
}) => {
  const { label, required, yearRange } = field;

  const handleChangeMonth = (_, month) => {
    onChange(field, { ...value, month });
  };

  const handleChangeYear = (_, year) => {
    onChange(field, { ...value, year });
  };

  const isUnanswered = getIsInputUnanswered(value?.month) || getIsInputUnanswered(value?.year);

  return (
    <div className={styles.root}>
      <Label
        indicateUnanswered={indicateUnanswered}
        isReadonly={isReadonly}
        isRequired={required}
        isUnanswered={isUnanswered}
      >
        {label}
      </Label>
      <div className={styles.lateralFields}>
        <MonthInput
          isReadonly={isReadonly}
          onChange={handleChangeMonth}
          value={value?.month}
        />
        <YearInput
          field={{ range: yearRange }}
          isReadonly={isReadonly}
          onChange={handleChangeYear}
          value={value?.year}
        />
      </div>
    </div>
  );
};

export default MonthYearInput;

import { FIELD_TYPES } from "lib/consts";
import { getIsInputUnanswered, getIsMultiselectUnanswered } from "./input";

// Note that making use of flattened form configs is limited. It's implemented in field lookup, default values, and required fields. It will not work for excessive nesting of groups or repeatable groups until further developed. Currently meant for basic use only.

export const getFields = (formConfig) => {
  // get top level set of fields from a form config's set of pages and build into a flattened array
  let fields = formConfig.pages.flatMap((p) => p.fields);
  // go through form config and find nested fields (to infinite layers) and fields found within options
  for (let i = 0; i < fields.length; i++) {
    const field = fields[i];
    // function to add nested fields to top level of array. Also, adds groupKey to each nested field (if parent has a groupKey)
    const addFields = (details) => {
      let groupKey;
      if(field.type === FIELD_TYPES.GROUP) {
        groupKey = field.key;
      } else if (field.groupKey) {
        groupKey = field.groupKey;
      }
      // Note that repeatableGroupKey will not be added to a field if it has a groupKey
      let repeatableGroupKey;
      if(field.type === FIELD_TYPES.REPEATABLE_GROUPS) {
        repeatableGroupKey = field.key;
      } else if (field.repeatableGroupKey) {
        repeatableGroupKey = field.repeatableGroupKey;
      }
      if (Array.isArray(details)) {
        if (groupKey) {
          details.forEach((nestedField) => { nestedField.groupKey = groupKey });
        } else if(repeatableGroupKey) {
          details.forEach((nestedField) => { nestedField.repeatableGroupKey = repeatableGroupKey });
        }
        fields = fields.concat(details);
      } else {
        if (groupKey) {
          details.groupKey = groupKey;
        } else if(repeatableGroupKey) {
          details.repeatableGroupKey = repeatableGroupKey;
        }
        fields.push(details);
      }
    };
    if (field.details) {
      addFields(field.details);
    }
    if (field.fields) {
      addFields(field.fields);
    }
    if (field.options) {
      field.options.forEach((option) => {
        if (option.details) {
          addFields(option.details);
        }
      });
    }
  }
  return fields;
};

// @todo setting defaults works for non-grouped fields and for the first input group layer, but it does not work for any nested input groups
// @todo setting defaults does not work for repeatable groups
// takes a form config and returns the set of default values to be used by the Form component
export const getDefaults = (formConfig) => {
  const fields = getFields(formConfig);
  const defaultValues = {};
  fields.forEach((field) => {
    if (Object.keys(field).includes("defaultValue")) {
      let defaultValue;
      if (field.type === FIELD_TYPES.CHECKBOX_GROUP) {
        defaultValue = {};
        field.defaultValue.forEach((value) => {
          defaultValue[value] = true;
        });
      } else {
        defaultValue = field.defaultValue;
      }
      if (field.groupKey) {
        if (defaultValues[field.groupKey]) {
          defaultValues[field.groupKey][field.key] = defaultValue;
        } else {
          defaultValues[field.groupKey] = { [field.key]: defaultValue };
        }
      } else {
        defaultValues[field.key] = defaultValue;
      }
    }
  });
  return defaultValues;
};

export const makeOptions = (values=[], placeholder) => {
  const options = values.map((v) => {
    return {
      label: v,
      value: v,
    };
  });
  if(placeholder) {
    options.unshift({
      label: placeholder
    });
  }
  return options;
};

export const getOptionsRange = (values = [], placeholder = "Choose...") => {
  return makeOptions(values, placeholder);
};

export const getFlattenedRequiredFields = (formConfig) => {
  const flattenedFields = getFields(formConfig);
  const requiredFields = flattenedFields.filter(field => !!field.required);
  return requiredFields;
};

// @todo not currently validating 3 field types that cannot be handled by default
// not meant for field type of group or repeatable group (validate fields within groups instead)
export const validateField = ({ field, value }) => {
  let isFieldValid;

  switch(field.type) {
    case FIELD_TYPES.CHECKBOX_GROUP:
      isFieldValid = !getIsMultiselectUnanswered(value);
      break;
    case FIELD_TYPES.BODY_PARTS:
      // not set up for this field type
      isFieldValid = true;
      break;
    case FIELD_TYPES.FILE:
      // not set up for this field type
      isFieldValid = true;
      break;
    case FIELD_TYPES.MONTH_YEAR:
      // not set up for this field type
      isFieldValid = true;
      break;
    default:
      isFieldValid = !getIsInputUnanswered(value);
  }

  return isFieldValid;
};

export const validateForm = ({ flattenedRequiredFields, values = {} }) => {
  for(const field of flattenedRequiredFields) {
    let isFieldValid;

    if(field.groupKey) {
      isFieldValid = validateField({ field, value: values[field.groupKey]?.[field.key] });
    } else if(field.repeatableGroupKey) {
      const groupsOfValues = values[field.repeatableGroupKey];
      if(!groupsOfValues?.length) {
        isFieldValid = false;
      } else {
        isFieldValid = groupsOfValues.every(group => validateField({ field, value: group[field.key] }));
      }
    } else {
      isFieldValid = validateField({ field, value: values[field.key] });
    }

    if(!isFieldValid) {
      return false;
    }
  }

  return true;
};
